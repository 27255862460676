import {BaseValidator} from './base-validator';

export abstract class Validator<T> extends BaseValidator<T> {
  public checkRequired(fieldName: string): this {
    const field = this.object[fieldName];

    if (field === null || field === undefined || field === '') {
      this.addError(fieldName, 'This field is required');
    }

    return this;
  }

  public checkMinLength(fieldName: string, len: number): this {
    const field = this.object[fieldName];

    if (!field) {
      return this;
    }

    if (field.length < len) {
      this.addError(fieldName, `Minimum length is ${len}`);
    }

    return this;
  }

  public checkMaxLength(fieldName: string, len: number): this {
    const field = this.object[fieldName];

    if (!field) {
      return this;
    }

    if (field.length > len) {
      this.addError(fieldName, `Maximum length is ${len}`);
    }

    return this;
  }

  public checkMinValue(fieldName: string, minValue: number): this {
    const field = this.object[fieldName];

    if (!field) {
      return this;
    }

    if (field < minValue) {
      this.addError(fieldName, `Minimum value is ${minValue}`);
    }

    return this;
  }

  public checkMaxValue(fieldName: string, maxValue: number): this {
    const field = this.object[fieldName];

    if (!field) {
      return this;
    }

    if (field > maxValue) {
      this.addError(fieldName, `Maximum value is ${maxValue}`);
    }

    return this;
  }

  public checkEmail(fieldName: string): this {
    const field = this.object[fieldName];

    if (!field) {
      return this;
    }

    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(field)) {
      this.addError(fieldName, 'This field must be a valid email');
    }

    return this;
  }

  public checkPhoneNumber(fieldName: string): this {
    const field = this.object[fieldName];

    if (!field) {
      return this;
    }

    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,10}$/im;

    if (!re.test(field)) {
      this.addError(
        fieldName,
        'This field must be a valid phone number. Example: +3163633634'
      );
    }

    return this;
  }
}
