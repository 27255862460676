export abstract class BaseValidator<T> {
  /**
   * Contains all the errors for the `object`.
   * This object map is empty if the `object` passed all validation rules.
   */
  public errors: any = {};

  /**
   * Object value to validate.
   */
  protected object!: {[key: string]: any};

  constructor(object: T) {
    this.setObject(object);
  }

  public setObject(object: T) {
    this.object = object;
  }

  /**
   * Specific validators must implement this method.
   */
  public abstract validate(): boolean;

  public clearErrors(fieldName?: string): void {
    if (fieldName) {
      delete this.errors[fieldName];
    } else {
      this.errors = {};
    }
  }

  public isValid(fieldName?: string): boolean {
    if (fieldName) {
      return !this.errors.hasOwnProperty(fieldName);
    } else {
      return Object.keys(this.errors).length === 0;
    }
  }

  public addError(fieldName: string, message: string) {
    this.errors[fieldName] = message;
  }

  public getError(fieldName: string) {
    return this.errors[fieldName];
  }

  public handleServerValidation(serverValidationData: any) {
    if (!serverValidationData) {
      console.error('handleServerValidation() empty serverValidationData');
      return;
    }

    if (
      serverValidationData.message === 'error.validation' &&
      serverValidationData.fieldErrors
    ) {
      for (const key in serverValidationData.fieldErrors) {
        const value = serverValidationData.fieldErrors[key];
        this.addError(key, value);
      }
    }
  }
}
